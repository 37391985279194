<template>
  <div>
    <v-card flat :dark="isDarkTheme">
      <v-list :style="{
          overflow: enableSearch ? 'auto' : 'unset',
          height: enableSearch ? '200px' : 'auto'
        }"
      >
        <div role="listitem"
          v-if="bookmarks.length === 0"
          style="text-align: center"
          class="pb-8 pt-8"
        >
          No Bookmarks to show.
        </div>
        <div v-if="bookmarks.length > 0">
        <div v-for="category in bookmarkCategories" :key="category.tab+'_tab'"><!--{{category}}
        {{category.tab}} !-->
        <div class="ml-4 mt-2" style="font-weight: normal;font-size: 14px;line-height: 20px;height:30px;color: #6D7079;">{{ category.tab}}</div>
        <div
          v-for="bookmark in (bookmarks.filter(bookmark => bookmark.tag ? bookmark.tag.toLowerCase() == category.tab.toLowerCase() : category.tab == 'General' ? true : false))"
          :key="bookmark.id"
        >
          <v-list-item 
            @click="()=> { openBookmark(bookmark.uri) }" target="_blank">
            <v-list-item-content class="firstColumn">
            <span
                class="bookmarkColorCircle"
                :style="`background-color:${bookmark.colour ? bookmark.colour : '#e4e4e6'}`"
              ></span></v-list-item-content>
            <v-list-item-content
             style="border-bottom:0.5px solid #E4E4E6" class="bookmarkName"
              >
              <div class="pl-1">{{ bookmark.title }}</div>
            </v-list-item-content>
            </v-list-item>
        </div>
        </div>
        </div>
      </v-list>
    </v-card>
  </div>
</template>
<script>
import lodash from 'lodash';
import { mapGetters } from "vuex"
export default {
  name: "Bookmarks",
  data() {
    return {
      openDialog: false,
      mySearch: "", // Index of bookmark open in dialog
      bookmark: null, // Current bookmark in dialog
      }
  },
  props: {
    bookmarkLabel: { type: String, default: "Student Bookmarks" },
    personal: { type: Boolean, default: false },
    enableSearch: { type: Boolean, default: false },
    userName: { type: String, default: "personal" },
    bookmarksObj: {
      type: Array,
      default: function () {
        return []
      }
    },
    filters:{ type: String, default: ""},
    edit: { type: Boolean, default: true }
  },
  computed: {
    ...mapGetters({
      profile: "profile"
    }),
    bookmarkCategories(){
      let bookmarksCats = [{id:"General_1",tab:"General"}];
      if(this.bookmarks.length > 0){
      this.bookmarks.forEach((bookmark,index) =>{
          let tag = bookmark.tag ? bookmark.tag : "General";
          let isCatExist = bookmarksCats.filter(cat => tag == cat.tab).length;
          if(isCatExist == 0){
           bookmarksCats.push({id:tag+"_"+index,tab:tag})
          }
      });
      }
      bookmarksCats = lodash.orderBy(bookmarksCats,["tab"],["asc"]);
      let isCustomEssentials = bookmarksCats.findIndex(cats => cats.tab == 'Custom Essentials');
      if(isCustomEssentials != -1){
        bookmarksCats.splice(isCustomEssentials , 1);
        bookmarksCats = [{id:'Custom Essentials_1',tab:'Custom Essentials'},...bookmarksCats];
      }
      return bookmarksCats;      
    },
    bookmarks: {
      get() {
        let personalBookmarks = this.bookmarksObj;
        let filter = this.filters;
        if(this.filters && this.filters !=""){
          personalBookmarks = this.bookmarksObj.filter(bookmark => bookmark.title.toLowerCase().indexOf(filter) != -1 || bookmark.uri.toLowerCase().indexOf(filter) != -1);    
        }
        return personalBookmarks
      },
      set(bookmarks) {
        return bookmarks
      }
    },
    profileUserName() {
      return this.profile.username
    },
    isDarkTheme() {
      return this.$store.getters.isDarkTheme
    },
    isKidsTheme() {
      return this.$store.getters.isKidsTheme
    }
  },
  methods: {
    getBookmarkCssClass(index) {
      if (index === this.bookmarkMovingUp) {
        return "themeTileBackgroundColor bookmarkMovingUp"
      } else if (index === this.bookmarkMovingDown) {
        return "themeTileBackgroundColor bookmarkMovingDown"
      }
    },
    searchBookmarks(value) {
      this.mySearch = value
    },
    openBookmark(url){
      url = url.replace("http://","https://");
      //eslint-disable-next-line
      NativeJS.openWindow(url,2);
    }
  }
}
</script>

<style scoped lang="scss">
.v-list {
  padding: 0;
}

.v-list-item__action {
  display: block;
  margin: 0 !important;
}
.v-list-item__content:not(.firstColumn){ padding: 20px 10px !important;}
.v-card__title > img {
  width: 24px;
  margin-right: 0.5em;
}
.firstColumn {
  flex: 0;
  min-width: 60px;
  padding: 0;

  .bookmarkColorCircle {
    width: 37px;
    height: 37px;
    flex: none;
    border-radius: 50%;
  }
}

.editBookmarks,
.newBookmark {
  font-size: inherit !important;
  height: 52px;
  width: 52px;
  margin: -0.25em 0;
}

.editBookmarks {
  margin-left: 0.5em;
}

.removeBookmark {
  color: #ee3c48 !important;
}

.bookmarkName {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  color: #000000;  
}
.theme--dark .bookmarkName{
  color: #FFFFFF;
}

/*
  Bookmark reordering classes below. Bookmarks only get reordered upwards, one at a time.
  So when reordering, the bookmark being moved up gets a "bookmarkMovingUp" class,
  and the one above it gets a "bookmarkMovingDown" class. This allows them to animate
  up and down at the same time.
*/
.bookmarkMovingUp,
.bookmarkMovingDown {
  top: 0;
  position: relative;
  pointer-events: none;
  border-radius: 12px;

  hr {
    visibility: hidden;
  }
}

.bookmarkMovingUp {
  animation: slideUp 0.5s 1;
  animation-fill-mode: forwards;

  @keyframes slideUp {
    100% {
      top: -3em;
    }
    50% {
      // Animate a shadow and expand scale at the halfway mark to give the
      // appearance of the bookmark coming towards us.
      box-shadow: 0 0.25em 1em rgba(0, 0, 0, 0.5);
      transform: scale(1.05);
    }
  }
}

.bookmarkMovingDown {
  animation: slideDown 0.5s 1;
  animation-fill-mode: forwards;
  @keyframes slideDown {
    100% {
      top: 3em;
    }
  }
}
</style>
