<template>
  <div>
    <appBarMobile
      color="primary"
      app-name="my-nsw-gov"
      title="Personal Bookmarks"
      :show-search-filter="true"
      search-filter-type="Bookmarks"
      search-label="Search Bookmarks"
    >
      <template slot="appIcon">
        <AppIcon
          app-name="my-nsw-gov"
          :transparent="true"
          size="50px"
          class="mt-2"
        />
      </template>
    </appBarMobile>
    <Bookmarks class="ma-n4"
      :bookmarksObj="bookmarksData"
      :filters="filters.Bookmarks"
    />
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import Bookmarks from "@/components/Bookmarks";
  import appBarMobile from "@/components/appBarMobile";
  import { AppIcon } from "@nswdoe/doe-ui-core";
  export default {
    components: {
      Bookmarks,
      appBarMobile,
      AppIcon
    },
    computed: {
      ...mapGetters({
        tokens: 'tokens',
        bookmarksData: "myBookmarks",
        filters:"filters"
      })
    },
    created() {
      this.$store.dispatch("fetchMyBookmarks");
      window.scrollTo(0,0);
    }
  }
</script>
<style scoped>
::v-deep .v-main__wrap .container{
  padding: 4px !important;
}
</style>